import React, { ReactElement } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
import Home from './components/Home';
import './App.css';
import NavigationBar from './components/Navigation';
import AuthScreen from './components/Auth';
import Dashboard from './components/Dashboard';
import TermsAndConditions from './components/Legal';
import NotFound from './components/NotFound';
import TournamentTable from './components/Tournament';
import Rankings from './components/Rankings';
import History from './components/BattleHistory';
import CreateMatch from './components/CreateMatch';
import Registration from './components/Auth/Registration';
import SignIn from './components/Auth/SignIn';
import { getLoggedUser } from './utils';
import Topup from './components/Topup';
import VerificationPage from './components/VerificationPage';
import SEO from './SEO';

// import WithdrawPopup from './components/Withdraw';

function App(): ReactElement {
  // const navigate = useNavigate();

  const handleSwitchToSignIn = () => {
    window.location.href = '/signin';
  };

  const handleSwitchToRegister = () => {
    window.location.href = '/register';
  };
  // Get Username
  const username = getLoggedUser('username');

  return (
    <BrowserRouter>
      <SEO />
      <NavigationBar />
      {/* <AuthScreen /> */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/matches' element={<CreateMatch username={username} />} />
        <Route path='/tournaments' element={<TournamentTable />} />
        <Route path='/rankings' element={<Rankings />} />
        <Route path='/battle-history' element={<History />} />
        {/* rankings */}
        <Route path='/user-profile' element={<AuthScreen />} />
        <Route path='/terms' element={<TermsAndConditions />} />
        <Route path='/dashboard' element={<Dashboard username={username} />} />
        {/* Match */}
        {/* Auth */}
        <Route
          path='/register'
          element={<Registration onSwitch={handleSwitchToSignIn} />}
        />
        <Route
          path='/signin'
          element={<SignIn onSwitch={handleSwitchToRegister} />}
        />
        {/* Topup */}
        <Route path='/topup' element={<Topup />} />
        {/* VERIFICATION */}
        <Route path='/verify-payment' element={<VerificationPage />} />
        {/* Withdraw */}
        {/* <Route path='/withdraw' element={<WithdrawPopup />} /> */}
        {/* Game From Homepage */}
        <Route path='/game/*' element={<CreateMatch username={username} />} />
        {/* TODO: Make username dynamic */}
        <Route
          path='/join-create'
          element={<CreateMatch username={username} />}
        />
        {/* Dynamic route */}
        <Route path='/user/:userId' element={<TermsAndConditions />} />
        {/* 404 page for undefined routes */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
