import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Game } from '../../types';

interface Props {
  content: Game;
}

const GameCard = ({ content }: Props): ReactElement => {
  const { id, title, thumbnail, short_description, genre, game_url } = content;
  // TODO: Change link
  // const link = `/game/${id}`;
  const link = `/game/${game_url}`;

  return (
    <Link to={link}>
      <img
        src={`/assets/images/thumbs/${thumbnail}.png`}
        alt={`${title} logo`}
      />
      <h2>{title}</h2>
      <p>{short_description}</p>
      <p>{genre}</p>
    </Link>
  );

  // return (
  //   <Link to={link}>
  //     <img src={thumbnail} alt={`${title} logo`} />
  //     <h2>{title}</h2>
  //     <p>{short_description}</p>
  //     <p>{genre}</p>
  //   </Link>
  // );
};

export default GameCard;
