import React, { ReactElement } from 'react';
import { Game } from '../../types';
import GameCard from '../GameCard';

interface Props {
  err?: string;
  games: Game[];
}

const GameListRender = ({ err, games }: Props): ReactElement => {
  console.log('from render: ', games);
  if (err) {
    // console.log({ err });
    return <p>Unable to fetch games</p>;
  }

  console.log('game length: ', games.length);
  if (!games?.length) {
    // console.log('game length2: ', games.data.length);
    return <p>No games?🤔️, that's odd. Try refreshing the page</p>;
  }

  return (
    <div className='game-list-container'>
      {/* {console.log({ games })} */}
      <ul className='game-list'>
        {games.map((game) => (
          <li className='game-card' key={game.id}>
            <GameCard content={game} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GameListRender;
