import React, { useState, useEffect } from 'react';
import './BattleHistory.css';

interface Game {
  id: number;
  datePlayed: string; // ISO 8601 date string
  gameName: string;
  playerName: string;
  guildName: string;
  result: string; // e.g., "Win" or "Loss"
  amountStaked: number;
}

// Sample data for games played
const games: Game[] = [
  {
    id: 1,
    datePlayed: '2024-07-02',
    gameName: 'Poker',
    playerName: 'Player3',
    guildName: 'Dark Knights',
    result: 'Win',
    amountStaked: 1500,
  },
  {
    id: 2,
    datePlayed: '2024-07-01',
    gameName: 'Chess',
    playerName: 'Player1',
    guildName: 'The Elite',
    result: 'Win',
    amountStaked: 1000,
  },
  {
    id: 3,
    datePlayed: '2024-07-01',
    gameName: 'Checkers',
    playerName: 'Player2',
    guildName: 'Warriors of Light',
    result: 'Loss',
    amountStaked: 500,
  },
];

const History: React.FC = () => {
  const [filteredGames, setFilteredGames] = useState<Game[]>([]);
  const [filterCriteria, setFilterCriteria] = useState(''); // Initial filter criteria
  const [filterValue, setFilterValue] = useState(''); // Initial filter value

  useEffect(() => {
    // Sort games by date in descending order
    const sortedGames = [...games].sort(
      (a, b) =>
        new Date(b.datePlayed).getTime() - new Date(a.datePlayed).getTime()
    );
    setFilteredGames(sortedGames);
  }, []);

  const handleFilter = () => {
    const filtered = filteredGames.filter((game) => {
      switch (filterCriteria) {
        case 'date':
          return game.datePlayed.includes(filterValue);
        case 'gameName':
          return game.gameName
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        case 'playerName':
          return game.playerName
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        case 'guildName':
          return game.guildName
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        default:
          return true; // No filtering
      }
    });
    setFilteredGames(filtered);
  };

  return (
    <div className='history-page'>
      <h1>Game Records</h1>

      <div className='filter-section'>
        <label htmlFor='filter-criteria'>Filter by:</label>
        <select
          id='filter-criteria'
          value={filterCriteria}
          onChange={(e) => setFilterCriteria(e.target.value)}
        >
          <option value=''>Select Criteria</option>
          <option value='date'>Date</option>
          <option value='gameName'>Game Played</option>
          <option value='playerName'>Player Name</option>
          <option value='guildName'>Guild Name</option>
        </select>

        <input
          type='text'
          placeholder={`Enter ${filterCriteria || 'value'}`}
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
        />

        <button className='filter-button' onClick={handleFilter}>
          Apply Filter
        </button>
      </div>

      <div className='game-list'>
        {filteredGames.map((game) => (
          <div key={game.id} className='game-card'>
            <h2>{game.gameName}</h2>
            <p>Date Played: {game.datePlayed}</p>
            <p>Player Name: {game.playerName}</p>
            <p>Guild Name: {game.guildName}</p>
            <p>Result: {game.result}</p>
            <p>Amount Staked: ${game.amountStaked.toLocaleString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default History;
