import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Auth.css';
import { delay, updateLoggedUser } from '../../utils';
import API_URL from '../../config';

interface SignInProps {
  onSwitch: () => void; // Define the type for the onSwitch prop
}

const SignIn: React.FC<SignInProps> = ({ onSwitch }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [wins, setWins] = useState(0);
  const [loss, setLoss] = useState(0);
  // const [userToken, setUserToken] = useState<string>('');

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle form submission logic
    setError('');
    setSuccess('');

    if (!validateEmail(email)) {
      setError('Invalid email format');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/auth/signin`, {
        email,
        password,
      });
      // Handle successful registration (e.g., show a success message or redirect)
      // console.log(response.data.message);
      // console.log(response.data['user-token']);
      // save token locally
      // setUserToken(response.data['user-token']);
      if (response.data) {
        const userToken = response.data['user-token'];
        const username = response.data['username'];
        const userCurrentBalance = response.data['currentBalance'];
        const usersStats = response.data['userStats'];
        const wins = usersStats['wins'];
        const loss = usersStats['losses'];
        updateLoggedUser('username', username);
        updateLoggedUser('userToken', userToken);
        updateLoggedUser('userBalance', userCurrentBalance);
        updateLoggedUser('wins', wins);
        updateLoggedUser('losses', loss);
        // localStorage.setItem(
        //   'menace-playground-user-token',
        //   response.data['user-token']
        // );
        // console.log('api login response: ', response?.data);
        // TODO: Fix ignore
        // @ts-ignore
        setSuccess(response?.data?.message || 'Login success');
        // Temporart fix ???
        // Check if a game (route) was selected originally
        // window.location.href = '/game/free_fire';

        delay(1000, () => {
          window.location.href = '/dashboard';
        });
      }

      // window.location.href = '/dashboard';
      // onSwitch(); // works???
    } catch (err) {
      // TODO: Fix ignore
      // @ts-ignore
      setError(err.response?.data?.message || 'Login failed');
    }
  };

  useEffect(() => {}, []);

  return (
    <div className='auth-container'>
      <div className='auth-form'>
        <h2>Sign In</h2>
        <form onSubmit={handleSubmit}>
          {success && <p className='success'>{success}</p>}
          <div className='form-group'>
            <label>Email</label>
            <input
              type='email'
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <label>Password</label>
            <input
              type='password'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          {error && <p className='error'>{error}</p>}
          <button type='submit'>Sign In</button>
        </form>
        <p>
          Already have an account? <span onClick={onSwitch}>Register here</span>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
