import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'react-datepicker'; // Assuming you are using react-datepicker
import 'react-datepicker/dist/react-datepicker.css';
// Timezone
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

import { Match, Matches } from '../../types';
import './CreateMatch.css';
import {
  showToast,
  copyToClipboard,
  formatToCurrency,
  getLoggedUser,
  dispayDateTime,
  delay,
  updateLoggedUser,
  sortMatchesByStatus,
  generateMatchId,
  getGameID,
  // fetchMatches,
} from '../../utils';
// import { min } from 'date-fns';
import FilterMatches from '../FilterMatches';
import API_URL from '../../config';

// Define the shape of the component's props
interface CreateMatchProps {
  username: string;
}

let abbToFul = {
  free_fire: 'Free Fire',
  cod: 'Call Of Duty',
  mlbb: 'Mobile Legends',
  mini_militia: 'Mini Militia',
  pubg: 'PUBG',
  asphalt_9: 'Asphalt 9',
};

const CreateMatch: React.FC<CreateMatchProps> = ({ username }) => {
  // const location = useLocation();
  // const selectedGame = location.pathname.split('/')[2];
  // console.log({ location: location.pathname.split('/') });

  const [opponent, setOpponent] = useState('');
  const [matchDate, setMatchDate] = useState<Date | null>(null);
  const [matchId, setMatchId] = useState<string | null>(null);
  // const [games, setGames] = useState(dummyGames); // Using dummy data
  const [matches, setMatches] = useState<Matches[]>([]);
  // const [allMatches, setAllMatches] = useState<Matches[]>([]);
  // const [games, setGames] = useState<Matches[]>([]);
  const [isMatchPrivate, setIsMatchPrivate] = useState<boolean>(false);
  const [gameTitle, setGameTitle] = useState<string>();
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<'Pending' | 'Accepted'>('Pending'); // New status state
  const [numPlayers, setNumPlayers] = useState<number>(); // Number of players
  const [stake, setStake] = useState<number>(5000); // Stake Amount
  const [unlimitedAmmo, setUnlimitedAmmo] = useState<boolean>(false);
  const [numRounds, setNumRounds] = useState<number>(7);
  // Filter state
  // const [filteredMatches, setFilteredMatches] = useState(matches); // 'matches' is your full matches data array
  // const [userGameID, setUserGameID] = useState<String>(''); // User Game ID
  // const [allowSpectators, setAllowSpectators] = useState<boolean>(false); // Allow spectators

  const notificationMessage =
    'You can use this Match ID to accept a challenge or spectate.';

  // const sortMatchesByStatus = async (matches: Matches[]) => {
  //   const statusOrder = ['pending', 'accepted', 'completed', 'cancelled'];
  //   const sortedMatches = matches.sort((a: Matches, b: Matches) => {
  //     return (
  //       statusOrder.indexOf(a.matchStatus) - statusOrder.indexOf(b.matchStatus)
  //     );
  //   });

  //   return sortedMatches;
  // };

  // const getGameID = (gameAbbrevation: string) => {
  //   return getLoggedUser(`${gameAbbrevation}_id`);
  // };

  // function generateMatchId(username: string): string {
  //   // Truncate the username to a max of 5 characters to keep it concise
  //   const truncatedUsername = username.substring(0, 5);

  //   // Generate a UUID and take the first segment to keep it shorter and unique
  //   const uuidPart = uuidv4().split('-')[0];

  //   // Combine both parts
  //   return `${truncatedUsername}-${uuidPart}`;
  // }

  const createMatch = async () => {
    const loggedUser = getLoggedUser('username');

    console.log({ loggedUser });
    if (loggedUser === '') {
      setError('You need to be logged in');
      // Redirect to signin page on user not logged in
      window.location.href = '/signin';
      return;
    }

    if (!gameTitle) {
      setError('Select a game title.');
      // switch (gameTitle) {
      //   case 'mlbb':
      //     if (gameTitle === 'mlbb') setError('5 max players');
      //     break;

      //   default:
      //     break;
      // }
      return;
    }
    const userIGNID = getGameID(gameTitle);

    if (!userIGNID) {
      setError("Game ID not set! Check 'Manage Details in your dashboard'");
      return;
    }

    console.log({ userIGNID });
    if (!numPlayers) {
      setError('Select number of players.');

      // @ts-ignore
      // if (numPlayers >= 6 && gameTitle === 'mlbb') setError('5 max players');
      return;
    }

    if (!matchDate) {
      setError('Select a match date.');
      return;
    }

    // gameTitle === "mlbb" ? setUserGameID('') : ''

    // if (!userGameID) {
    //   setError('Enter your valid Game ID.');
    //   return;
    // }

    if (!stake || stake < 5000) {
      setError('Select a valid stake amount.');
      return;
    }

    try {
      const matchId = generateMatchId(loggedUser); // Generate a unique match ID
      const isoDate = matchDate.toISOString();

      const userToken = getLoggedUser('userToken');
      console.log({
        matchDate,
        isoDate,
        // gmtPlusOneDate,
        numRounds,
        unlimitedAmmo,
        opponent,
        matchId,
        gameTitle,
        numPlayers,
        // userGameID,
        stake,
        isMatchPrivate,
        // allowSpectators,
      });

      axios
        .post(
          `${API_URL}/api/matches`,
          {
            // matchDateTime: matchDate,
            matchTitle: gameTitle,
            matchDateTime: isoDate,
            matchID: matchId,
            numPlayers,
            opponentID: '',
            opponentName: '',
            // userGameID,
            stakeAmount: stake,
            isPrivate: isMatchPrivate,
            unlimitedAmmo,
            numRounds,
          },
          {
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        // .then((res) => res.data)
        .then((res) => {
          const apiData = res?.data;
          console.log('api data: ', apiData);
          if (apiData?.message === 'success') {
            showToast('Match create success!');
            const updatedBalance = apiData?.data?.updatedBalance;

            updateLoggedUser('userBalance', updatedBalance);
            // Reset
            // Reload Matches list
            fetchMatches();
          }
        })
        .catch((e) => {
          setError('Something went wrong!');
          return;
        });

      setError(null); // Clear any previous error
      //fetchGames(); // Refresh the list of available games
      // axios.post('/games');

      // finally, reset all fields
      // Clear the input fields after match creation
      console.log('resetting...');
      setMatchDate(null);
      // setOpponent('');
      // setNumPlayers(undefined); // Reset to default
      // setStake(5000); // Reset to default stake
      // setUserGameID('');
      // setError(null); // Clear any previous error
      setMatchId(matchId);
      // delay(2000)
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Handle known Axios error
        setError(error.response?.data?.message || error.message);
      } else {
        // Handle unexpected error
        setError((error as Error).message);
      }
    }
  };

  const fetchMatches = async () => {
    // Typing the axios GET request to expect an array of Game
    axios
      .get<Matches[]>(`${API_URL}/api/matches`, {
        headers: {
          'content-type': 'application/json',
        },
      })
      // .then((res) => res.data)
      .then(async (res) => {
        // TODO: Prepend created match to matches instead of repeatedly calling fetchMatches() x infinity
        // Set the games state with the fetched data
        // @ts-ignore
        console.log('api data: ', res.data.matches);
        // @ts-ignore
        const matches = res.data?.matches;

        // Rearrange matches before setting and displaying
        // const statusOrder = ['pending', 'accepted', 'completed', 'cancelled'];
        // const sortedMatches = matches.sort((a: Matches, b: Matches) => {
        //   return (
        //     statusOrder.indexOf(a.matchStatus) -
        //     statusOrder.indexOf(b.matchStatus)
        //   );
        // });

        // Rearrange matches before setting and displaying
        const sortedMatches = await sortMatchesByStatus(matches);

        // TODO: Save api games data locally for fast load times and refresh in background if needed
        localStorage.setItem(
          'menace_playground_data_matches',
          // @ts-ignore
          JSON.stringify(sortedMatches)
          // JSON.stringify(res.data.matches)
        );
        // @ts-ignore
        setMatches(sortedMatches);
        // setAllMatches(sortedMatches);
        // console.log({ matches });
        // console.log({ allMatches });
        // setMatches(res.data.matches);
      })
      .catch((e) => {
        // TODO: Set to empty array if error
        localStorage.setItem(
          'menace_playground_data_matches',
          JSON.stringify([])
        );
        // TODO: remove this error message log
        console.log('error: ', 'remove this error message log');
        // setErr(e.message);
      });
  };

  useEffect(() => {
    // TODO: Check for local saved data, display it, fetchMatches, update page, update local saved data
    fetchMatches();
    // .then(() =>
    //   localStorage.setItem(
    //     'menace_playground_data_matches',
    //     JSON.stringify(matches)
    //   )
    // );
    // // Typing the axios GET request to expect an array of Game
    // axios
    //   .get<Matches[]>('${API_URL}/matches', {
    //     headers: {
    //       'content-type': 'application/json',
    //     },
    //   })
    //   // .then((res) => res.data)
    //   .then((res) => {
    //     // Set the games state with the fetched data
    //     // @ts-ignore
    //     console.log('api data: ', res.data.matches);
    //     // TODO: Save api games data locally for fast load times and refresh in background if needed
    //     // @ts-ignore
    //     setMatches(res.data.matches);
    //   })
    //   .catch((e) => {
    //     // TODO: remove this error message log
    //     console.log('error: ', 'remove this error message log');
    //     // setErr(e.message);
    //   });
  }, []);
  // TODO: Add dynamic page hints
  // Utility function to truncate the matchId
  const truncateMatchId = (id: string) => {
    return id.length > 8 ? `${id.substring(0, 8)}...` : id;
  };

  const isToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  // Define your timezone, e.g., 'Europe/London' for GMT+1
  // const timezone = 'Europe/London';
  const timezone = 'Africa/Lagos';
  const handleDateChange = (date: Date | null) => {
    if (date) {
      // Convert the selected date to UTC in the specified timezone
      const utcDate = fromZonedTime(date, timezone);
      // console.log({ utcDate });
      // TODO: Confirm you're saving correct date from here to db
      setMatchDate(utcDate);
    }
  };

  const handleStakeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = Number(e.target.value);

    // Ensure the value stays within the bounds
    if (inputValue < 5000) {
      inputValue = 5000;
    }
    // else if (inputValue > 100000) {
    //   inputValue = 100000;
    // }

    setStake(inputValue);
  };

  const handleRoundsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = Number(e.target.value);
    setNumRounds(inputValue);
  };

  const minDate = fromZonedTime(new Date(), timezone);
  const getMinDate = () => {
    // Get the current date and time in UTC
    const now = new Date();

    // Adjust to GMT+1 by adding 1 hour
    now.setHours(now.getUTCHours() + 1);

    // Round up to the nearest 10 minutes
    const minutes = now.getMinutes();
    const remainder = minutes % 10;
    if (remainder !== 0) {
      now.setMinutes(minutes + (10 - remainder));
    }

    return now;
  };

  const updateMatch = (
    matchAction: string,
    matchId: string,
    matchTitle: string
  ) => {
    const userIGNID = getGameID(matchTitle);

    const userToken = getLoggedUser('userToken');
    switch (matchAction) {
      case 'accept':
        if (!userIGNID) {
          showToast(
            "Game ID not set! Check 'Manage Details in your dashboard'"
          );
          return;
        }
        // TODO: Use showToast() when successful
        console.log('accepting match');
        axios
          .put(
            `${API_URL}/api/matches`,
            // .post<Matches[]>('${API_URL}/matches', {
            {
              matchID: matchId,
            },
            {
              headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
          // .then((res) => res.data)
          .then((res) => {
            // Show notifications
            const apiData = res?.data;
            console.log('api data: ', apiData);
            if (apiData?.message === 'success') {
              showToast('Match Accepted!');
              const updatedBalance = apiData?.data?.updatedBalance;

              updateLoggedUser('userBalance', updatedBalance);
              // Reload Matches list
              fetchMatches();
            }
          })
          .catch((e) => {
            // setError('Something went wrong!');
            showToast('You cannot accept this match!');
            return;
          });
        console.log('match accepted');
        break;

      case 'cancel':
        console.log('cancelleing match');
        axios
          .delete(`${API_URL}/api/matches`, {
            // .post<Matches[]>('${API_URL}/matches', {
            data: {
              matchID: matchId,
            },

            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${userToken}`,
            },
          })
          // .then((res) => res.data)
          .then((res) => {
            const apiData = res?.data;
            console.log('api data: ', apiData);
            if (apiData?.message === 'success') {
              showToast('Cancel success!');
              const updatedBalance = apiData?.data?.updatedBalance;

              console.log({ 'from cancel: ': updatedBalance });

              updateLoggedUser('userBalance', updatedBalance);
              // Reload Matches list
              fetchMatches();
            }
          })
          .catch((e) => {
            showToast('You cannot cancel this match!');
            // setError('Something went wrong!');
            return;
          });
        console.log('match cancelled');
        break;
      case 'withdraw':
        // TODO: Use showToast() when successful
        console.log('withdrawing from match');
        axios
          .patch(
            `${API_URL}/api/matches`,
            // .post<Matches[]>('${API_URL}/matches', {
            {
              matchID: matchId,
            },
            {
              headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
          // .then((res) => res.data)
          .then((res) => {
            // Show notifications
            // showToast('Match Accepted!');
            // // Reload Matches list
            // fetchMatches();
            // console.log('api data: ', res.data);

            const apiData = res?.data;
            console.log('api data: ', apiData);
            if (apiData?.message === 'success') {
              showToast('Withdraw success!');
              const updatedBalance = apiData?.data?.updatedBalance;

              console.log({ 'from withdraw: ': updatedBalance });

              updateLoggedUser('userBalance', updatedBalance);
              // Reload Matches list
              fetchMatches();
            }
          })
          .catch((e) => {
            showToast('You cannot withdraw from this match!');
            // setError('Something went wrong!');
            return;
          });
        console.log('withdrawn from match');
        break;

      default:
        break;
    }
  };

  const handleFilterChange = (filters: any) => {
    console.log({ filters });
    const currentUserId = getLoggedUser('username');
    const filtered = matches.filter((match) => {
      const matchStatusMatch = filters.matchStatus
        ? match.matchStatus === filters.matchStatus
        : true;
      const matchIDMatch = filters.matchID
        ? match.matchID.includes(filters.matchID)
        : true;
      const userIdMatch = filters.userId
        ? match.creatorMPID === filters.userId ||
          match.opponentMPID === filters.userId
        : true;
      const stakeMatch = filters.stake
        ? match.stakeAmount === Number(filters.stake)
        : true;
      const typeMatch =
        filters.type === 'myMatches'
          ? match.creatorName === currentUserId ||
            match.opponentName === currentUserId
          : true;

      return (
        matchStatusMatch &&
        matchIDMatch &&
        userIdMatch &&
        stakeMatch &&
        typeMatch
      );
    });
    // setFilteredMatches(filtered);
    setMatches(filtered);
  };

  return (
    <div className='create-match-container'>
      {/* TODO: Add match filtering */}
      <FilterMatches onFilterChange={handleFilterChange} />
      {/* Filter by: pending, user matches, match id */}
      <div className='cards-container'>
        <div className='card create-match-card'>
          <h3>Create a Match</h3>
          <div className='form-group'>
            <p className='creator-name'>Creator: {username}</p>

            {/* <select
              className='form-control'
              onChange={(e) =>
                setStatus(e.target.value as 'Pending' | 'Accepted')
              }
              value={status}
            >
              <option value='Pending'>Pending</option>
              <option value='Accepted'>Accepted</option>
            </select> */}

            <select
              className='form-control'
              value={gameTitle}
              onChange={(e) => setGameTitle(e.target.value)}
            >
              <option value=''>Select a game</option>
              {Object.entries(abbToFul).map(([objKey, objVal], index) => (
                <option onClick={(e) => {}} key={index} value={objKey}>
                  {objVal}
                </option>
              ))}
            </select>

            {/* <input type='text' placeholder={`Your ${gameTitle} Gamer ID`} /> */}
            {/* TODO: Make Game ID dynamic and already set */}
            {/* GAME ID INPUT */}
            {/* <input
              type='text'
              placeholder='Your Game ID'
              onChange={(e) => setUserGameID(e.target.value)}
            /> */}

            {/* TODO: Confirm if below is useful (still???) */}
            {status === 'Accepted' && (
              <input
                type='text'
                value={opponent}
                onChange={(e) => setOpponent(e.target.value)}
                placeholder='Opponent'
                className='form-control'
                disabled={status !== 'Accepted'}
              />
            )}

            <DatePicker
              selected={matchDate ? toZonedTime(matchDate, timezone) : null}
              onChange={handleDateChange}
              showTimeSelect
              // dateFormat='Pp'
              // timeFormat='HH:mm' // Use 24-hour format
              // dateFormat='Pp, HH:mm' // Date and 24-hour time format
              timeIntervals={10} // Show times in 10-minute increments
              //
              timeFormat='HH:mm' // 24-hour time format
              // dateFormat='MM/dd/yyyy, HH:mm' // Desired format: MM/DD/YYYY, 24-hour time
              dateFormat='dd/MM/yyyy, HH:mm' // Desired format: MM/DD/YYYY, 24-hour time
              //
              className='form-control date-picker'
              placeholderText='Select Match Date & Time'
              // TODO: Make minDate localeTimeString() when supporing multiple timezones and countries
              minDate={getMinDate()} // Prevent past dates in the specified timezone
              minTime={
                isToday(matchDate || toZonedTime(new Date(), timezone))
                  ? toZonedTime(new Date(), timezone)
                  : new Date(0, 0, 0, 0, 0)
              } // Prevent past times for today
              maxTime={new Date(0, 0, 0, 23, 59)} // Max time is 11:59 PM
            />

            {/* <input
              type='number'
              value={numPlayers}
              onChange={(e) => setNumPlayers(Number(e.target.value))}
              placeholder='2 is 2 vs 2, 3 is 3 vs 3 etc...'
              className='form-control'
              min='1'
              max='8'
            /> */}

            {/* TODO: Make select value dynamic. Example: Max for MLBB is 5 */}
            <select
              value={numPlayers}
              onChange={(e) => setNumPlayers(Number(e.target.value))}
              className='form-control'
            >
              <option value='' disabled>
                Select number of players
              </option>{' '}
              {/* Placeholder */}
              <option value=''>Select number of players</option>
              <option value={1}>1 vs 1</option>
              <option value={2}>2 vs 2</option>
              <option value={3}>3 vs 3</option>
              <option value={4}>4 vs 4</option>
              <option value={5}>5 vs 5</option>
              <option value={6}>6 vs 6</option>
              <option value={7}>7 vs 7</option>
              <option value={8}>8 vs 8</option>
            </select>

            {/* TODO: Add public and private game option */}
            <label className='privacy' style={{ display: 'flex' }}>
              Private Match
              <input
                type='checkbox'
                checked={isMatchPrivate}
                onChange={() => setIsMatchPrivate(!isMatchPrivate)}
              />
            </label>

            {/* ROUNDS AND AMMO */}
            {/* ROUNDS AND AMMO */}
            {/* ROUNDS AND AMMO */}

            {gameTitle && gameTitle === 'free_fire' && (
              <>
                {/* Unlimited Ammo Checkbox */}
                <label className='privacy' style={{ display: 'flex' }}>
                  Unlimited Ammo
                  <input
                    type='checkbox'
                    checked={unlimitedAmmo}
                    onChange={() => setUnlimitedAmmo(!unlimitedAmmo)}
                  />
                </label>

                {/* Number of Rounds Input */}
                <i style={{ color: 'pink' }}>How many rounds?</i>
                <input
                  type='number'
                  placeholder='How many rounds?'
                  min={1}
                  max={15}
                  step={1}
                  value={numRounds}
                  onChange={handleRoundsChange}
                  onInput={(e) => {
                    const target = e.target as HTMLInputElement;
                    target.value = target.value.replace(/[^0-9]/g, ''); // Only allow numbers
                  }}
                />

                <br />
                <br />
              </>
            )}

            {/* ROUNDS AND AMMO */}
            {/* ROUNDS AND AMMO */}
            {/* ROUNDS AND AMMO */}

            {/* STAKE INPUT */}
            <input
              type='number'
              placeholder='Enter a number'
              value={stake}
              min={5000}
              // max={100000}
              step={100} // Move in increments of 100
              onChange={handleStakeChange}
              onInput={(e) => {
                const target = e.target as HTMLInputElement; // Cast to HTMLInputElement
                target.value = target.value.replace(/[^0-9.]/g, ''); // allows only numbers and dots

                // setWithdrawalAmount(target.value);
              }}
              // onChange={(e) => setStake(e.target.value)}
            />
            {/* <label>
              <input
                type='checkbox'
                checked={allowSpectators}
                onChange={() => setAllowSpectators(!allowSpectators)}
              />
              Allow Spectators
            </label> */}

            {/* TODO: Reset all fields on click */}
            <button onClick={createMatch} className='btn btn-primary'>
              Create Match
            </button>

            {matchId && (
              <div className='match-id'>
                {error === null ? (
                  <p>
                    Match ID:{' '}
                    <span
                      className='copyable truncated-match-id'
                      onClick={() => copyToClipboard(false, matchId, '')}
                    >
                      {truncateMatchId(matchId)}
                    </span>
                  </p>
                ) : (
                  ''
                )}
              </div>
            )}
            {error && <p className='error-message'>{error}</p>}
          </div>
        </div>

        {matches.map((match) => (
          <div
            key={match.matchID}
            className={`card game-card ${
              match.matchStatus === 'pending'
                ? 'pending'
                : match.matchStatus === 'accepted'
                ? 'accepted'
                : match.matchStatus === 'completed'
                ? 'completed'
                : 'cancelled'
            }`}
          >
            <h4>
              {match.matchTitle === 'free_fire'
                ? 'Free Fire'
                : match.matchTitle === 'mlbb'
                ? 'Mobile Legends'
                : match.matchTitle === 'mini_militia'
                ? 'Mini Militia'
                : match.matchTitle === 'cod'
                ? 'Call of Duty'
                : match.matchTitle === 'pubg'
                ? 'PUBG'
                : match.matchTitle}
            </h4>
            <p>Creator: {match.creatorName}</p>
            {match.opponentName !== '' && <p>Opponent: {match.opponentName}</p>}
            <p>
              Match ID:{' '}
              <span
                className='copyable truncated-match-id'
                onClick={() =>
                  copyToClipboard(true, match.matchID, notificationMessage)
                }
              >
                {truncateMatchId(match.matchID)}
              </span>
            </p>
            <p>Time: {new Date(match.matchDateTime).toLocaleString()}</p>
            <p>Status: {match.matchStatus}</p>
            {match.matchStatus === 'Accepted' && (
              <p>Opponent: {match.opponentName}</p>
            )}
            {/* <p>Queued Spectators: {match.}</p> */}
            <p>Stake: {formatToCurrency(match.stakeAmount)}</p>
            {/* {match.isPrivate && (
              <i style={{ color: 'red' }}>Match is private</i>
            )}

            <br />

            {match.unlimitedAmmo && (
              <i style={{ color: 'red' }}>Unlimited Ammo</i>
            )}
            <br />
            {match.matchTitle === 'free_fire' && (
              <i style={{ color: 'red' }}>{match.numRounds}</i>
            )} */}

            {/* Match Info */}
            {/* Match Info */}
            <div className='match-info'>
              {match.isPrivate && (
                <i className='match-text'>Match is private</i>
              )}

              {match.unlimitedAmmo && (
                <i className='match-text'>Unlimited Ammo</i>
              )}

              {match.matchTitle === 'free_fire' && (
                <i className='match-text'>{match.numRounds} rounds</i>
              )}
            </div>
            {/* Match Info */}
            {/* Match Info */}

            {/* TODO: Add time check validation for cancellation possiblity. 5 mins before match max (???) */}
            {match.creatorName === username &&
            (match.matchStatus === 'pending' ||
              match.matchStatus === 'accepted') ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  updateMatch('cancel', match.matchID, match.matchTitle);
                }}
                className='btn btn-secondary'
              >
                Cancel Match
              </button>
            ) : match.matchStatus === 'completed' || //TODO: Cancelled matches count(???)
              match.matchStatus === 'cancelled' ? (
              <button
                disabled
                // onClick={(e) => {
                //   e.preventDefault();
                //   // updateMatch('withdraw', match.matchID);
                // }}
                className='btn btn-secondary'
              >
                {/* Capitalize first letter */}
                {match.matchStatus.charAt(0).toUpperCase() +
                  match.matchStatus.slice(1)}
              </button>
            ) : match.opponentName === username ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  updateMatch('withdraw', match.matchID, match.matchTitle);
                }}
                className='btn btn-secondary'
              >
                Withdraw
              </button>
            ) : match.opponentName !== '' &&
              match.matchStatus === 'accepted' ? (
              ''
            ) : match.isPrivate ? (
              ''
            ) : (
              // {}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  updateMatch('accept', match.matchID, match.matchTitle);
                }}
                className='btn btn-secondary'
              >
                Challenge
              </button>
            )}
          </div>
        ))}

        {/* {games.map((game) => (
          <div key={game.id} className='card game-card'>
            <h4>{game.title}</h4>
            <p>Creator: {game.creator}</p>
            <p>
              Match ID:{' '}
              <span
                className='copyable truncated-match-id'
                onClick={() =>
                  copyToClipboard(true, game.matchId, notificationMessage)
                }
              >
                {truncateMatchId(game.matchId)}
              </span>
            </p>
            <p>Time: {new Date(game.matchDate).toLocaleString()}</p>
            <p>Status: {game.status}</p>
            {game.status === 'Accepted' && <p>Opponent: {game.opponent}</p>}
            <p>Queued Spectators: {game.spectators}</p>
            <p>Stake: {formatToCurrency(game.stake)}</p>
            {game.status === 'Pending' && (
              <button className='btn btn-secondary'>Challenge</button>
            )}
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default CreateMatch;
