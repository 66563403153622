import React, { useState } from 'react';
import axios from 'axios';
import './Auth.css';
import API_URL from '../../config';

interface RegistrationProps {
  onSwitch: () => void;
}

const Registration: React.FC<RegistrationProps> = ({ onSwitch }) => {
  const [email, setEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    if (!validateEmail(email)) {
      setError('Invalid email format');
      return;
    }

    const trimmedPassword = password.trim();
    if (!trimmedPassword || trimmedPassword.length <= 7) {
      setError('Set a secure password');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/auth/register`, {
        email,
        username,
        password,
      });
      // Handle successful registration (e.g., show a success message or redirect)
      console.log(response.data.message);
      // TODO: Fix ignore
      // @ts-ignore
      setSuccess(response?.data?.message || 'Registration success');
      // Navigate to sign in page
      onSwitch();
    } catch (err) {
      // TODO: Fix ignore
      // @ts-ignore
      setError(err.response?.data?.message || 'Registration failed.');
    }
  };

  return (
    <div className='auth-container'>
      <div className='auth-form'>
        <h2>Register</h2>
        <form onSubmit={handleSubmit}>
          {success && <p className='success'>{success}</p>}
          <div className='form-group'>
            <label>Email</label>
            <input
              type='email'
              name='email'
              placeholder='Your email address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <label>Username</label>
            <input
              type='text'
              name='username'
              placeholder='Set a username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>

          <div className='form-group'>
            <label>Password</label>
            <input
              type='password'
              name='password'
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className='error'>{error}</p>}
          <button type='submit'>Register</button>
        </form>
        <p>
          Already have an account? <span onClick={onSwitch}>Sign in here</span>
        </p>
      </div>
    </div>
  );
};

export default Registration;
