import React, { useEffect, useState } from 'react';
// import defaultProfilePic from './defaultProfilePic.png'; // Default image for profile
import { Link } from 'react-router-dom'; // For navigation

import Notifications from './Notifications';
import {
  formatToCurrency,
  getLoggedUser,
  showToast,
  sortMatchesByStatus,
  updateLoggedUser,
} from '../../utils';
import { Matches } from '../../types';
import axios from 'axios';

import './Dashboard.css';
import './WithdrawPopup.css';
import './UserDetails.css';
import API_URL from '../../config';

// interface Match {
//   id: string;
//   gameName: string;
//   gameTime: string;
//   gameType: string; // Game type, e.g., "1 v 1"
// }

// interface User {
//   username: string;
//   profilePicture?: string;
//   balance: number;
//   wins: number;
//   losses: number;
//   upcomingMatches: Match[]; // List of upcoming matches
// }

// const Dashboard: React.FC<{ user: User }> = ({ user }) => {
const Dashboard: React.FC<{ username: String }> = ({ username }) => {
  const [userMatches, setUserMatches] = useState<Matches[] | []>([]);
  const [userWins, setUserWins] = useState<number>(0);
  const [userLosses, setUserLosses] = useState<number>(0);
  const [userBalance, setUserBalance] = useState<number>(0);
  const [withdrawalAmount, setWithdrawalAmount] = useState('');

  const [showUserDetailsPopup, setShowUserDetailsPopup] = useState(false);
  const [gameIds, setGameIds] = useState({
    mobileLegends: '',
    freeFire: '',
    callOfDuty: '',
  });
  const [bankDetails, setBankDetails] = useState({
    bankName: '',
    accountNumber: '',
    accountName: '',
  });

  // Withdraw Setup
  // Withdraw Setup
  // Withdraw Setup
  const [showWithdrawPopup, setShowWithdrawPopup] = useState(false);

  const openWithdrawPopup = () => setShowWithdrawPopup(true);
  const closeWithdrawPopup = () => setShowWithdrawPopup(false);

  const openUserDetailsPopup = () => setShowUserDetailsPopup(true);
  const closeUserDetailsPopup = () => setShowUserDetailsPopup(false);

  // Withdraw Setup
  // Withdraw Setup
  // Withdraw Setup

  const isAuthenticated = !!localStorage.getItem(
    'menace_playground_data_userToken'
  );

  if (!isAuthenticated) {
    window.location.href = '/signin';
  }

  // Format user balance ahead
  // TODO: Fix this
  // const userBalance = formatToCurrency(user.balance);
  // const getUserBalance = () => {
  //   const currentBalance = localStorage.getItem(
  //     'menace_playground_data_userBalance'
  //   );

  //   // TODO: Make api call for currentBalance before user data fill
  //   currentBalance && setUserBalance(parseInt(currentBalance));
  // };

  const getUserStats = () => {
    const currentBalance = localStorage.getItem(
      'menace_playground_data_userBalance'
    );

    const currentWins = localStorage.getItem('menace_playground_data_wins');
    const currentLosses = localStorage.getItem('menace_playground_data_losses');

    // TODO: Make api call for currentStats before user data fill
    currentWins && setUserWins(parseInt(currentWins));
    currentLosses && setUserLosses(parseInt(currentLosses));
    // TODO: Make api call for currentBalance before user data fill
    currentBalance && setUserBalance(parseInt(currentBalance));
  };

  // const handleWithdraw = (e: React.ChangeEvent<HTMLInputElement>) => {
  const handleWithdraw = () => {
    let withdrawValue = parseInt(withdrawalAmount);

    // TODO: Confirm user has enough balance
    let userBalance =
      localStorage.getItem('menace_playground_data_userBalance') || 0;

    // @ts-ignore
    let intBalance = parseInt(userBalance);
    console.log({ intBalance });
    if (withdrawValue > intBalance) {
      showToast('Insufficient Balance');
      setWithdrawalAmount('');
      return;
    }
    // Withdrawal logic goes here
    // TODO: Send to server
    const userToken = getLoggedUser('userToken');
    axios
      .put(
        `${API_URL}/api/userconfig`,
        {
          instruction: 'deduct-balance',
          amount: withdrawValue,
        },
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        // Check response to confirm if match watch created successfully
        const message = res?.data?.message;
        const curBal = res?.data?.currentBalance;

        // console.log({ message });
        // console.log({ curBal });
        // updateLoggedUser('userBalance', curBal);
        // setUserBalance(curBal);
        // Message will be failed
        if (message === 'success') {
          updateLoggedUser('userBalance', curBal);
          setUserBalance(curBal);
          closeWithdrawPopup();
          showToast('Withdrawal Submitted! 🙂️ ');
        }
      })
      .catch((e) => {
        // setError('Something went wrong!');
        return;
      });
    console.log('Withdrawal submitted.');
  };

  const fetchUserStats = async () => {
    const userToken = getLoggedUser('userToken');

    // Typing the axios POST request to expect user current data
    axios
      .post(
        `${API_URL}/api/userconfig`,
        {},
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then(async (res) => {
        // TODO: Prepend created match to matches instead of repeatedly calling fetchUserStats() x infinity
        // Set the games state with the fetched data
        // @ts-ignore
        console.log('api data: ', res?.data);
        const currentBalance = res?.data?.data?.currentBalance;
        const userWins = res?.data?.data?.userWins;
        const userLosses = res?.data?.data?.userLosses;
        const userMatches = res?.data?.data?.userMatches;
        const userBankDetails = res?.data?.data?.bankDetails;
        const userGameIDs = res?.data?.data?.gameIDs;

        const userBAName = userBankDetails?.accountName;
        const userBANum = userBankDetails?.accountNumber;
        const userBankName = userBankDetails?.bankName;

        const FFID = userGameIDs?.freeFire;
        const MLID = userGameIDs?.mobileLegends;
        const CODID = userGameIDs?.callOfDuty;

        console.log({ userBankDetails });
        console.log({ userGameIDs });

        // console.log({ currentBalance, userWins, userLosses, userMatches });

        updateLoggedUser('userBalance', currentBalance);
        updateLoggedUser('losses', userLosses);
        updateLoggedUser('wins', userWins);
        updateLoggedUser('free_fire_id', FFID);
        updateLoggedUser('mlbb_id', MLID);
        updateLoggedUser('cod_id', CODID);
        setBankDetails({
          bankName: userBankName,
          accountNumber: userBANum,
          accountName: userBAName,
        });
        setGameIds({ mobileLegends: MLID, freeFire: FFID, callOfDuty: CODID });
        const sortedMatches = await sortMatchesByStatus(userMatches);
        setUserMatches(sortedMatches);
      })
      .catch((e) => {
        // TODO: Set to empty array if error
        // localStorage.setItem(
        //   'menace_playground_data_matches',
        //   JSON.stringify([])
        // );
        // TODO: remove this error message log
        // console.log('error: ', 'remove this error message log');
        // setErr(e.message);
        updateLoggedUser('userBalance', '0');
        updateLoggedUser('losses', '0');
        updateLoggedUser('wins', '0');
        setUserMatches([]);
      });
  };

  const logOut = () => {
    localStorage.removeItem('menace_playground_data_username');
    localStorage.removeItem('menace_playground_data_userToken');
    localStorage.removeItem('menace_playground_data_userBalance');
    localStorage.removeItem('menace_playground_data_wins');
    localStorage.removeItem('menace_playground_data_losses');
  };

  const filterMatches = (): void | [] => {
    const allMatches = localStorage.getItem('menace_playground_data_matches');

    // console.log({ allMatches });

    if (!allMatches) {
      setUserMatches([]);
      return;
    }

    const allMatchesParsed: Matches[] | [] = JSON.parse(allMatches);
    // console.log({ u1: allMatchesParsed });
    const userMatchList: Matches[] | [] =
      allMatchesParsed.filter(
        (match) =>
          match.creatorName === username || match.opponentName === username
      ) || [];

    // console.log({ userMatchList });

    setUserMatches(userMatchList);
  };

  // const openUserDetailsPopup = () => setShowUserDetailsPopup(true);
  // const closeUserDetailsPopup = () => setShowUserDetailsPopup(false);

  const handleGameIdsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setGameIds((prev) => ({ ...prev, [name]: value }));
  };

  const handleBankDetailsChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setBankDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleUserDetailsSubmit = () => {
    // Handle form submission to server here
    console.log('Submitted Game IDs: ', gameIds);
    console.log('Submitted Bank Details: ', bankDetails);
    const userToken = getLoggedUser('userToken');

    axios
      .post(
        `${API_URL}/api/userdetails`,
        {
          gameIds,
          bankDetails,
        },
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then(async (res) => {
        // TODO: Prepend created match to matches instead of repeatedly calling fetchUserStats() x infinity
        // Set the games state with the fetched data
        // @ts-ignore
        console.log('api data: ', res?.data);
        console.log('status: ', res?.data?.status);
        setGameIds(gameIds);
        setBankDetails(bankDetails);

        // const updatedBankDetails = res?.data?.data?.bankDetails;
        // const updatedGameIDs = res?.data?.data?.gameIDs;

        const userBAName = bankDetails?.accountName;
        const userBANum = bankDetails?.accountNumber;
        const userBankName = bankDetails?.bankName;

        console.log({ userBAName, userBANum, userBankName });

        const FFID = gameIds?.freeFire;
        const MLID = gameIds?.mobileLegends;
        const CODID = gameIds?.callOfDuty;

        // console.log({ bankDetails });
        // console.log({ gameIds });

        // // console.log({ currentBalance, userWins, userLosses, userMatches });

        updateLoggedUser('ffid', FFID);
        updateLoggedUser('mlid', MLID);
        updateLoggedUser('codid', CODID);
        // setBankDetails({
        //   bankName: userBankName,
        //   accountNumber: userBANum,
        //   accountName: userBAName,
        // });
        // setGameIds({ mobileLegends: MLID, freeFire: FFID, callOfDuty: CODID });
        // setUserMatches(userMatches);
      })
      .catch((e) => {
        // TODO: Set to empty array if error
        // localStorage.setItem(
        //   'menace_playground_data_matches',
        //   JSON.stringify([])
        // );
        // TODO: remove this error message log
        // console.log('error: ', 'remove this error message log');
        // setErr(e.message);
        updateLoggedUser('userBalance', '0');
        updateLoggedUser('losses', '0');
        updateLoggedUser('wins', '0');
        setUserMatches([]);
      });
    closeUserDetailsPopup();
    showToast('Details saved successfully!');
  };

  useEffect(() => {
    fetchUserStats();
    getUserStats();
    // getUserBalance();
    filterMatches();
  }, []);

  return (
    <div className='dashboard'>
      {/* Profile Section */}
      <div className='profile-section'>
        {/* <img
          src={
            user.profilePicture ||
            'https://th.bing.com/th/id/OIP.TFVy3lunETBJ1G66nRM3MgHaHb?rs=1&pid=ImgDetMain'
          }
          alt={`${user.username}'s Profile`}
          className='profile-picture'
        /> */}
        {/*  */}
        <span className='username'>{username}</span>

        <Link
          to='/'
          style={{
            listStyle: 'none',
            gap: '2rem',
            color: '#fff',
            textDecoration: 'none',
            fontWeight: '1.5rem',
            borderRadius: '20px',
          }}
          className='nav-links'
          onClick={logOut}
        >
          Logout
        </Link>
      </div>

      {/* Balance Section */}
      <div className='balance-section'>
        {/* <strong>Balance: ${userBalance}</strong> */}
        {/* NOTE: &#8358; is the Naira symbol */}
        <strong>
          Balance: &#8358;
          {/* Add refresh icon just after balance to refresh balance (???) */}
          {formatToCurrency(userBalance)}
        </strong>
      </div>

      {showWithdrawPopup && (
        <div className='withdraw-popup-overlay'>
          <div className='withdraw-popup'>
            {/* <button className='close-btn' onClick={closeWithdrawPopup}>
              X
            </button> */}
            <button className='close-btn' onClick={closeWithdrawPopup}>
              Close
            </button>
            <h3>Withdraw Funds</h3>
            <input
              type='number'
              placeholder='Enter withdrawal amount'
              // onInput={(e) => {
              //   e.target.value = e.target.value.replace(/[^0-9.]/g, '');
              // }}
              value={withdrawalAmount}
              min={1000}
              onInput={(e) => {
                const target = e.target as HTMLInputElement; // Cast to HTMLInputElement
                target.value = target.value.replace(/[^0-9.]/g, ''); // allows only numbers and dots

                // setWithdrawalAmount(target.value);
              }}
              onChange={(e) => {
                setWithdrawalAmount(e.target.value);
              }}
            />
            <button className='btn btn-primary' onClick={handleWithdraw}>
              Submit Withdrawal
            </button>
          </div>
        </div>
      )}

      {showUserDetailsPopup && (
        <div className='user-details-popup-overlay'>
          <div className='user-details-popup'>
            <button className='close-btn' onClick={closeUserDetailsPopup}>
              Close
            </button>
            <h3>Your Details</h3>
            <form onSubmit={handleUserDetailsSubmit}>
              <h4>Game IDs</h4>
              <label>
                Free Fire ID:
                <input
                  type='text'
                  name='freeFire'
                  value={gameIds.freeFire}
                  onChange={handleGameIdsChange}
                  placeholder='Enter your Free Fire ID'
                />
              </label>
              <label>
                Mobile Legends ID:
                <input
                  type='text'
                  name='mobileLegends'
                  value={gameIds.mobileLegends}
                  onChange={handleGameIdsChange}
                  placeholder='Enter your Mobile Legends ID'
                />
              </label>

              <label>
                Call of Duty ID:
                <input
                  type='text'
                  name='callOfDuty'
                  value={gameIds.callOfDuty}
                  onChange={handleGameIdsChange}
                  placeholder='Enter your Call of Duty ID'
                />
              </label>

              <h4>Bank Details</h4>
              <label>
                Bank Name:
                <select
                  name='bankName'
                  value={bankDetails.bankName}
                  onChange={handleBankDetailsChange}
                >
                  <option value=''>Select Bank</option>
                  <option value='Opay'>Opay</option>
                  <option value='Wema'>Wema</option>
                  <option value='GTBank'>GTBank</option>
                  <option value='Access Bank'>Access Bank</option>
                  <option value='First Bank'>First Bank</option>
                  {/* Add more banks as needed */}
                </select>
              </label>
              <label>
                Account Number:
                <input
                  type='text'
                  name='accountNumber'
                  value={bankDetails.accountNumber}
                  onChange={handleBankDetailsChange}
                  placeholder='Enter your Account Number'
                />
              </label>
              <label>
                Account Name:
                <input
                  type='text'
                  name='accountName'
                  value={bankDetails.accountName}
                  onChange={handleBankDetailsChange}
                  placeholder='Enter your Account Name'
                />
              </label>

              <button type='submit' className='btn btn-primary'>
                Save Details
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Actions Section - Interactive Cards */}
      <div className='actions-section'>
        {/* <Link to='/challenge-screen' className='action-card'>
          Go to Challenge Screen
        </Link> */}
        <Link to='/join-create' className='action-card'>
          Create or Join Challenge
        </Link>
        <button className='action-card' onClick={openWithdrawPopup}>
          Withdraw
        </button>
        <Link to='/topup' className='action-card'>
          Top Up
        </Link>
        <button className='action-card' onClick={openUserDetailsPopup}>
          Manage Details
        </button>
      </div>

      {/* Match Count Section */}
      <div className='match-count-section'>
        {/* TODO: Fix this */}
        {/* TODO: User stats table */}
        <span>Wins: {userWins}</span>
        {/* Spacing between wins and losses */}
        <span style={{ marginLeft: 20 }}>Losses: {userLosses}</span>
      </div>

      {/* Upcoming Matches Section */}
      <div className='upcoming-matches-section'>
        <h3>Upcoming Matches</h3>
        {/* TODO: Filter out or send cancelled and completed matches to bottom */}
        <div className='match-cards'>
          {userMatches.map((match) => (
            <div className='match-card' key={match.matchID}>
              {}
              <h4>
                {' '}
                {match.matchTitle === 'free_fire'
                  ? 'Free Fire'
                  : match.matchTitle === 'mlbb'
                  ? 'Mobile Legends'
                  : match.matchTitle === 'mini_militia'
                  ? 'Mini Militia'
                  : match.matchTitle === 'cod'
                  ? 'Call of Duty'
                  : match.matchTitle === 'pubg'
                  ? 'PUBG'
                  : match.matchTitle}
              </h4>
              <p>Time: {new Date(match.matchDateTime).toLocaleString()}</p>
              <p>Status: {match.matchStatus}</p>
              <p>Match ID: {match.matchID}</p>
              {match.matchTitle === 'free_fire' && (
                <p>Rounds: {match.numRounds}</p>
              )}
              {match.isPrivate && <i>Private Match</i>}

              {/* Some button madness */}
              {/* TODO: ADD BUTTON MADNESS LATER!!! */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
