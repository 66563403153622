import React, { useState } from 'react';
import './FilterMatches.css';

interface FilterProps {
  onFilterChange: (filters: any) => void;
}

const FilterMatches: React.FC<FilterProps> = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({
    matchStatus: '',
    matchID: '',
    userId: '',
    stake: '',
    type: 'allMatches',
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const applyFilters = () => {
    onFilterChange(filters);
  };

  return (
    <div className='filter-container'>
      <h3 className='filter-title'>Filter Matches</h3>

      <div className='filter-options'>
        <select
          name='matchStatus'
          value={filters.matchStatus}
          onChange={handleInputChange}
          className='filter-input'
        >
          <option value=''>Status</option>
          <option value='pending'>Pending</option>
          <option value='accepted'>Accepted</option>
          <option value='cancelled'>Cancelled</option>
        </select>

        <input
          type='text'
          name='matchID'
          value={filters.matchID}
          placeholder='Match ID'
          onChange={handleInputChange}
          className='filter-input'
        />

        <select
          name='type'
          value={filters.type}
          onChange={handleInputChange}
          className='filter-input'
        >
          <option value='allMatches'>All Matches</option>
          <option value='myMatches'>My Matches</option>
        </select>

        <input
          type='text'
          name='userId'
          value={filters.userId}
          placeholder='User ID'
          onChange={handleInputChange}
          className='filter-input'
        />

        <input
          type='number'
          name='stake'
          value={filters.stake}
          placeholder='Stake Amount'
          onChange={handleInputChange}
          className='filter-input'
        />

        <button onClick={applyFilters} className='apply-button'>
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterMatches;
