// src/App.tsx
import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  site_name?: string;
}

const SEO: React.FC<SEOProps> = ({
  title = 'Menace Playground',
  description = `Menace Playground - The Ultimate Hub for Game Challenges & Competitive Gaming

Unleash your skills in the top gaming challenges platform. Compete, conquer, and rise to the top in a fair, moderated arena where only the best gamers prevail`,
  // image = 'https://menaceplayground.net/default-image.png',
  image = '/assets/images/og-image.png',
  url = 'https://menaceplayground.net',
  site_name = 'Menace Playground',
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property='og:title' content='Menace Playground' />
      <meta property='og:description' content={description} />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={image} />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content={site_name} />

      {/* Twitter Meta Tags */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image} />
    </Helmet>
  );
};

export default SEO;
