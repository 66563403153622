import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css'; // Import CSS for styling

const NotFound: React.FC = () => {
  return (
    <div className='not-found-container'>
      <h1>404 - Page Not Found</h1>
      <p>
        Oops! It seems like you've taken a wrong turn. Maybe you were looking
        for the next level up, but found a hidden trapdoor instead.
      </p>
      <p>
        Don't worry, we've got a map. Just click below to go back to safety.
      </p>
      <Link to='/' className='back-home-link'>
        Back to Home
      </Link>
      <p>
        If you still can't find your way, you might want to bring a compass next
        time.
      </p>
    </div>
  );
};

export default NotFound;
