import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './VerificationPage.css';
import API_URL from '../../config';
// import { delay } from '../../utils';

// interface RouteParams {
//   reference: string; // matches the route parameter name
// }

// interface RouteParams extends Record<string, string | undefined> {
//   reference: string;
// }

const VerificationPage: React.FC = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const reference = query.get('reference');
  console.log({ reference });

  const [status, setStatus] = useState<'loading' | 'success' | 'failed' | null>(
    null
  );
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    const verifyTransaction = async () => {
      if (!reference) return;

      setStatus('loading');
      try {
        const response = await axios.put(
          `${API_URL}/api/payments/paystack`,
          { reference },
          { headers: {} }
        );

        console.log({ verifycallresponse: response.data });
        if (response.data.status === 'success') {
          setStatus('success');
          // const topupAmount = response?.data?.data?.amount;
          // const prevBal = localStorage.getItem('')
          // console.log('data: ', response.data);
          setMessage('Payment successful! You can close this tab now.');
          // TODO: Update balance(???)
          // delay(1000, () => {
          //   window.close();
          // });
        } else {
          setStatus('failed');
          setMessage('Payment verification failed. Please contact support.');
        }
      } catch (error) {
        setStatus('failed');
        setMessage(
          'An error occurred while verifying the payment. Please try again.'
        );
      }
    };

    verifyTransaction();
  }, [reference]);

  return (
    <div className='verification-page'>
      <div className='verification-content'>
        {status === 'loading' && (
          <p className='loading-text'>Verifying payment...</p>
        )}
        {status === 'success' && <p className='success-text'>{message}</p>}
        {status === 'failed' && <p className='error-text'>{message}</p>}
      </div>
    </div>
  );
};

export default VerificationPage;
