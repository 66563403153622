import React, { ReactElement, useEffect, useState } from 'react';
import axios from 'axios';
import GameListRender from './GameListRender';
import { Game } from '../../types';
import API_URL from '../../config';
// // import { API_HOST, API_KEY } from './constants';

const GameList = (): ReactElement => {
  // Initialize the state with an empty array of Game
  const [games, setGames] = useState<Game[]>([]);
  const [err, setErr] = useState<string>('');

  useEffect(() => {
    // Typing the axios GET request to expect an array of Game
    axios
      .get<Game[]>(`${API_URL}/games`, {
        headers: {
          'content-type': 'application/json',
        },
      })
      // .then((res) => res.data)
      .then((res) => {
        // Set the games state with the fetched data
        // @ts-ignore
        setGames(res.data.data);
      })
      .catch((e) => setErr(e.message));
  }, []);

  useEffect(() => {
    console.log('Games state updated: ', games);
  }, [games]);

  if (err) {
    // return <div>Error: {err}</div>;
    return <div>No games?🤔️, that's odd. Try refreshing the page </div>;
  }

  // return (
  //   <>
  //     <h1>Game List</h1>
  //     {games.length > 0 ? (
  //       <GameListRender err={err} games={games} />
  //     ) : (
  //       <div>No games available</div>
  //     )}
  //   </>
  // );

  return <GameListRender err={err} games={games} />;
};

export default GameList;
