import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Matches } from '../types';
// TODO: ADD CUSTOM ERROR FUNCTION

export const fetchMatches = async () => {
  // Typing the axios GET request to expect an array of Game
  axios
    .get<Matches[]>('/api/matches', {
      headers: {
        'content-type': 'application/json',
      },
    })
    // .then((res) => res.data)
    .then((res) => {
      // Set the games state with the fetched data
      // @ts-ignore
      console.log('api data: ', res.data.matches);
      // TODO: Save api games data locally for fast load times and refresh in background if needed
      // @ts-ignore
      setMatches(res.data.matches);
    })
    .catch((e) => {
      // TODO: remove this error message log
      console.log('error: ', 'remove this error message log');
      // setErr(e.message);
    });
};

export const dispayDateTime = (dateTime: string) => {
  // const isoString = '2024-10-17T10:20:00.000Z';
  const date = new Date(dateTime);

  // Convert to GMT+1 by specifying the time zone
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'Europe/Berlin', // Example for GMT+1
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // 24-hour format
  };

  // Convert and format the date to GMT+1 and the desired format
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  console.log(formattedDate); // Output: "10/17/2024, 11:20:00"

  return formattedDate;
};
// export const dispayDateTime = () => {
//   const isoString = '2024-10-17T10:20:00.000Z';
//   const date = new Date(isoString);

//   const options: Intl.DateTimeFormatOptions = {
//     timeZone: 'Europe/Berlin', // Example for GMT+1
//     year: '2-digit', // Change to 2-digit for YY format
//     month: '2-digit',
//     day: '2-digit',
//     hour: '2-digit',
//     minute: '2-digit',
//     second: '2-digit',
//     hour12: false, // 24-hour format
//   };

//   // Convert and format the date to GMT+1
//   const formattedDateParts = new Intl.DateTimeFormat(
//     'en-US',
//     //@ts-ignore
//     options
//   ).formatToParts(date);

//   // Extract day, month, and year from formatted parts
//   const day = formattedDateParts.find((part) => part.type === 'day').value;
//   const month = formattedDateParts.find((part) => part.type === 'month').value;
//   const year = formattedDateParts.find((part) => part.type === 'year').value;

//   // Format as DD/MM/YY
//   const formattedDate = `${day}/${month}/${year}`;

//   console.log(formattedDate); // Output: "17/10/24"
// };
export const delay = (duration: number, command: () => void) => {
  setTimeout(() => {
    command(); // Execute the command after the delay
  }, duration);
};

export const showToast = (message: string) => {
  // This is a simple implementation, consider using a library like react-toastify for production
  const toast = document.createElement('div');
  toast.className = 'toast';
  toast.textContent = message;
  document.body.appendChild(toast);
  setTimeout(() => {
    document.body.removeChild(toast);
  }, 3000);
};

export const copyToClipboard = (
  toast: boolean,
  text: string,
  notificationMessage: string
) => {
  navigator.clipboard.writeText(text);
  alert('Match ID copied to clipboard!');

  toast && showToast(notificationMessage);
};

// Utility function to truncate the matchId
export const truncateMatchId = (id: string) => {
  return id.length > 8 ? `${id.substring(0, 8)}...` : id;
};

export const formatToCurrency = (number: number) => {
  return number.toLocaleString('en-US');
};

export const getLoggedUser = (data_type: string) => {
  let localData = localStorage.getItem(`menace_playground_data_${data_type}`);

  if (localData === null || !localData) {
    // Set logged user, ready for edits and update
    setLoggedUser(data_type);
    return '';
  }

  try {
    return localData;
  } catch (error) {
    console.error('Failed to parse localStorage data:', error);
    return ''; // Return empty string if parsing fails
  }
};

export const setLoggedUser = (data_type: string) => {
  localStorage.setItem(`menace_playground_data_${data_type}`, '');
};
export const updateLoggedUser = (key: string, value: string) => {
  localStorage.setItem(`menace_playground_data_${key}`, value);
};

export const customError = () => {};

export const openLinkInNewTab = (link: string) => {
  if (link) {
    window.open(link, '_blank');
  }
};

export const sortMatchesByStatus = async (matches: Matches[]) => {
  const statusOrder = ['pending', 'accepted', 'completed', 'cancelled'];
  const sortedMatches = matches.sort((a: Matches, b: Matches) => {
    return (
      statusOrder.indexOf(a.matchStatus) - statusOrder.indexOf(b.matchStatus)
    );
  });

  return sortedMatches;
};

export const generateMatchId = (username: string): string => {
  // Truncate the username to a max of 5 characters to keep it concise
  const truncatedUsername = username.substring(0, 5);

  // Generate a UUID and take the first segment to keep it shorter and unique
  const uuidPart = uuidv4().split('-')[0];

  // Combine both parts
  return `${truncatedUsername}-${uuidPart}`;
};

export const getGameID = (gameAbbrevation: string) => {
  return getLoggedUser(`${gameAbbrevation}_id`);
};
