import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions: React.FC = () => {
  return (
    <div className='terms-container'>
      <h1>Terms and Conditions</h1>

      <section>
        <h2>1. Staking and Challenges</h2>
        <p>
          1.1. <strong>Minimum Staking Balance</strong>: To participate in
          challenges, users must have a minimum balance of 5000 naira, 5 USDT or
          USDC, or $5.
        </p>
        <p>
          1.2. <strong>House Fee</strong>: After each completed challenge, the
          house takes 20% of the overall stake, and 80% is awarded to the winner
          or winning team.
        </p>
        <p>
          1.3. <strong>Forfeiture</strong>: Failure to show up for a scheduled
          match results in a forfeit. The team or player that showed up is
          awarded the prize.
        </p>
        <p>
          1.4. <strong>Cancellation Policy</strong>: To cancel a match, a notice
          must be given at least 1 hour ahead. If canceled, the potential stake
          fund will be reversed within one hour.
        </p>
      </section>

      <section>
        <h2>2. User Conduct</h2>
        <p>
          2.1. <strong>No Mods or Cheats</strong>: Users are prohibited from
          using cheats, hacks, aim bots, modified APKs, or other modifications
          that give an unfair advantage. Violations result in instant forfeiture
          and possible banning from the Website.
        </p>
        <p>
          2.2. <strong>Respectful Behavior</strong>: Users must avoid offensive
          language, harassment, or abusive behavior. Hate speech,
          discrimination, or bullying will not be tolerated.
        </p>
      </section>

      <section>
        <h2>3. Game Participation</h2>
        <p>
          3.1. <strong>Attendance</strong>: Failure to appear for a scheduled
          game results in forfeiture, and your opponent is awarded the prize.
        </p>
        <p>
          3.2. <strong>Connectivity</strong>: Users are responsible for their
          own internet connection. Disconnections during a match may lead to
          forfeiture unless there are extenuating circumstances.
        </p>
      </section>

      <section>
        <h2>4. User Accounts</h2>
        <p>
          4.1. <strong>Account Information</strong>: Users are responsible for
          maintaining the confidentiality of their account information,
          including usernames and passwords.
        </p>
        <p>
          4.2. <strong>Account Security</strong>: The Website is not responsible
          for unauthorized access or use of user accounts. Users should report
          suspicious activity immediately.
        </p>
      </section>

      <section>
        <h2>5. Intellectual Property</h2>
        <p>
          5.1. <strong>Ownership</strong>: The Website and all related content,
          including games and branding, are the property of Menace Playground or
          its licensors. Unauthorized use or reproduction is prohibited.
        </p>
      </section>

      <section>
        <h2>6. Contact Information</h2>
        <p>
          6.1. <strong>Contact Us</strong>: If you have questions about these
          terms and conditions, contact us at [Contact Information].
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
