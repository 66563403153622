import React from 'react';
import './TournamentTable.css';
import { formatToCurrency } from '../../utils';

interface Tournament {
  id: number;
  name: string;
  date: string;
  status: string; // e.g., "Upcoming", "Ongoing", "Completed"
  prizePool: number;
}

const tournaments: Tournament[] = [
  {
    id: 1,
    name: 'Battle of Legends',
    date: '2024-07-01',
    status: 'Completed',
    prizePool: 100000,
  },
  {
    id: 2,
    name: 'Ultimate Showdown',
    date: '2024-06-15',
    status: 'Completed',
    prizePool: 500000,
  },
  {
    id: 3,
    name: 'Masters Tournament',
    date: '2024-05-25',
    status: 'Completed',
    prizePool: 200000,
  },
];

const TournamentTable: React.FC = () => {
  return (
    <div className='tournament-table'>
      <h1>Tournaments</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Status</th>
            <th>Prize Pool</th>
          </tr>
        </thead>
        <tbody>
          {tournaments.map((tournament) => (
            <tr key={tournament.id}>
              <td>{tournament.name}</td>
              <td>{tournament.date}</td>
              <td>{tournament.status}</td>
              <td> &#8358; {formatToCurrency(tournament.prizePool)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Overlay for coming soon message */}
      <div className='coming-soon-overlay'>
        <p>Stay tuned! More exciting tournaments coming soon!</p>
      </div>
    </div>
  );
};

export default TournamentTable;
