import React, { useState } from 'react';
import axios from 'axios';
import './Topup.css';
import { delay, getLoggedUser, openLinkInNewTab, showToast } from '../../utils';
import API_URL from '../../config';
// const https = require('https');

declare global {
  interface Window {
    PaystackPop: any;
  }
}

interface ApiResponse {
  auth_url?: string;
  status: boolean;
  reference: string;
}

const Topup: React.FC = () => {
  const [selectedMethod, setSelectedMethod] = useState<
    'bank' | 'paystack' | null
  >(null);
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [amount, setAmount] = useState<number>(5000); // default top-up amount

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    // if (value <= 1000) {
    //   setAmount(1000);
    // }
    setAmount(isNaN(value) ? 0 : value); // update amount, defaulting to 0 if input is invalid
  };

  const handleBankTransfer = async () => {
    try {
      const uniqueCode = await axios.get<{ data: string }>(
        `${API_URL}/api/generate-topup-code`
      );
      setStatusMessage(
        `Please use code ${uniqueCode.data} in the payment description. Send the payment receipt to our Telegram for verification. Bank Details: WEMA Bank, Account Name: SAMEHADA TECH, Account Number: 0293387998`
      );
    } catch (error) {
      //   setStatusMessage(
      //     'Failed to generate bank transfer code. Please try again.'
      //   );

      setStatusMessage(
        'Currently unavailable, please try an alternative option.'
      );
    }
  };

  // TODO: Finalize the below function
  const handlePaystack = () => {
    // const axios = require('axios');
    setStatusMessage(null);
    const userToken = getLoggedUser('userToken');

    if (!userToken) {
      showToast('You need to be logged in!');
      delay(1000, () => (window.location.href = '/signin'));
    }

    const params = {
      amount,
    };

    axios
      .post(`${API_URL}/api/payments/paystack`, params, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((response) => {
        // TODO: Delete below log line
        console.log(response.data as ApiResponse);
        const apiData = response.data as ApiResponse;
        const auth_url = apiData.auth_url;

        if (auth_url) {
          openLinkInNewTab(auth_url);
        } else {
          setStatusMessage('Paystack unavailable at the moment');
        }
      })
      .catch((error) => {
        // console.error(error);
        setStatusMessage(
          'Something went wrong!, Maybe try logging out and in 🥴️'
        );
      });
  };

  return (
    <div className='topup-page'>
      <h3>Top up your balance</h3>
      {/* INPUT */}

      <div className='amount-input'>
        <label htmlFor='amount'>Enter Amount:</label>
        <input
          type='number'
          id='amount'
          value={amount}
          onChange={handleAmountChange}
          min='100'
          placeholder='Enter amount to top up'
          onInput={(e) => {
            const target = e.target as HTMLInputElement; // Cast to HTMLInputElement
            target.value = target.value.replace(/[^0-9.]/g, ''); // allows only numbers and dots
          }}
        />
      </div>

      {/* INPUT */}
      <div className='payment-options'>
        <button
          onClick={() => {
            setStatusMessage(null);
            setSelectedMethod('bank');
          }}
        >
          Bank Transfer
        </button>
        <button
          onClick={() => {
            setStatusMessage(null);
            setSelectedMethod('paystack');
          }}
        >
          Pay with Paystack
        </button>
        {/* <button
          onClick={() => {
            setStatusMessage(null);
            setSelectedMethod('flutterwave');
          }}
        >
          Pay with Flutterwave
        </button> */}
      </div>
      {selectedMethod === 'bank' && (
        <div className='bank-transfer-section'>
          <p>Please use the unique code below in the payment description.</p>
          <button onClick={handleBankTransfer}>
            Generate Bank Transfer Code
          </button>
        </div>
      )}
      {selectedMethod === 'paystack' && (
        <div className='paystack-section'>
          <button onClick={handlePaystack}>Proceed with Paystack</button>
        </div>
      )}
      {statusMessage && <p className='status-message'>{statusMessage}</p>}
    </div>
  );
};

export default Topup;
