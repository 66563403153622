// AuthScreen.jsx
import React, { useState } from 'react';
import SignIn from './SignIn';
import Registration from './Registration';

const AuthScreen = () => {
  const [isSignIn, setIsSignIn] = useState(true);

  const switchForm = () => {
    setIsSignIn(!isSignIn);
  };

  return (
    <div>
      {isSignIn ? (
        <SignIn onSwitch={switchForm} />
      ) : (
        <Registration onSwitch={switchForm} />
      )}
    </div>
  );
};

export default AuthScreen;
