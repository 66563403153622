import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavigationBar.css';

const NavigationBar: React.FC = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className='navbar'>
      <div className='navbar-container'>
        <Link to='/' className='logo'>
          Menace Playground
        </Link>
        <div className='menu-icon' onClick={toggleMobileMenu}>
          <span className='bar'></span>
          <span className='bar'></span>
          <span className='bar'></span>
        </div>
        <ul className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
          <li>
            <Link to='/matches' onClick={toggleMobileMenu}>
              Matches
            </Link>
          </li>
          <li>
            <Link to='/tournaments' onClick={toggleMobileMenu}>
              Tournaments
            </Link>
          </li>
          <li>
            <Link to='/rankings' onClick={toggleMobileMenu}>
              Rankings
            </Link>
          </li>
          {/* <li>
            <Link to='/battle-history' onClick={toggleMobileMenu}>
              Battle History
            </Link>
          </li> */}

          {!!localStorage.getItem('menace_playground_data_userToken') ? (
            <li>
              <Link to='/dashboard' onClick={toggleMobileMenu}>
                Dashboard
              </Link>
            </li>
          ) : (
            <li>
              <Link to='/signin' onClick={toggleMobileMenu}>
                Sign In
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default NavigationBar;
