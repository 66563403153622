import React, { useState } from 'react';
import './Rankings.css';
import { showToast } from '../../utils';

interface Guild {
  id: number;
  name: string;
  wins: number;
  losses: number;
  totalCashWon: number;
}

interface User {
  id: number;
  username: string;
  wins: number;
  losses: number;
  totalCashWon: number;
}

// Sample data for guilds and users
const guilds: Guild[] = [
  { id: 1, name: 'The Elite', wins: 250, losses: 50, totalCashWon: 50000 },
  {
    id: 2,
    name: 'Warriors of Light',
    wins: 200,
    losses: 60,
    totalCashWon: 45000,
  },
];

const users: User[] = [
  { id: 1, username: 'Player1', wins: 150, losses: 30, totalCashWon: 30000 },
  { id: 2, username: 'Player2', wins: 130, losses: 40, totalCashWon: 25000 },
];

const Rankings: React.FC = () => {
  const [isGuildView, setIsGuildView] = useState(true);

  const handleToggleView = () => {
    setIsGuildView(!isGuildView); // Toggle between guilds and users
  };

  const telegramAccount = 't.me/samehadatech';

  // TODO: Make auto open telegram on click!
  const rankingsChallenge = (type: string) => {
    switch (type) {
      case 'user':
        showToast(`Reach out on our telegram ${telegramAccount}`);
        break;

      case 'guild':
        showToast(`Reach out on our telegram ${telegramAccount}`);
        break;

      default:
        break;
    }
  };

  return (
    <div className='rankings'>
      <button className='toggle-view-button' onClick={handleToggleView}>
        {isGuildView ? 'Switch to User Rankings' : 'Switch to Guild Rankings'}
      </button>

      <h1>{isGuildView ? 'Top Rated Guilds' : 'Top Rated Players'}</h1>

      {/* Overlay for coming soon message */}
      <div className='coming-soon-overlay'>
        <p>Player and Guild Rankings will be updated momentarily!</p>
      </div>

      {isGuildView ? (
        <div>
          <div className='guild-list'>
            {guilds.map((guild) => (
              <div key={guild.id} className='guild-card'>
                <h2>{guild.name}</h2>
                <p>Wins: {guild.wins}</p>
                <p>Losses: {guild.losses}</p>
                <p>
                  Total Cash Won: &#8358; {guild.totalCashWon.toLocaleString()}
                </p>
                <button
                  className='challenge-button'
                  onClick={() => rankingsChallenge('guild')}
                >
                  Challenge Guild
                </button>
              </div>
            ))}
          </div>
          <button className='register-guild-button'>Register Guild</button>{' '}
          {/* Register guild CTA */}
        </div>
      ) : (
        <div className='user-list'>
          {users.map((user) => (
            <div key={user.id} className='user-card'>
              <h2>{user.username}</h2>
              <p>Wins: {user.wins}</p>
              <p>Losses: {user.losses}</p>
              <p>
                Total Cash Won: &#8358; {user.totalCashWon.toLocaleString()}
              </p>
              <button
                className='challenge-user-button'
                onClick={() => rankingsChallenge('user')}
              >
                Challenge User
              </button>{' '}
              {/* Challenge user */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Rankings;
